<template>
	<v-container class="fill-height" fluid>
		<ChangePassword class="mb-10" />
		<DeleteAccount />
	</v-container>
</template>

<script>
import ChangePassword from '../components/ChangePassword';
import DeleteAccount from '../components/DeleteAccount';

export default {
	name: 'UserAccountSettings',
	components: {
		ChangePassword,
		DeleteAccount,
	},
};
</script>

<style lang="scss" scoped></style>
