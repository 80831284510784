<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="6">
			<v-card class="elevation-20">
				<v-toolbar color="primary" dark flat>
					<v-toolbar-title>{{ $t('passwordChange') }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-card-text>
					<v-form ref="form">
						<!-- Current Password -->
						<v-text-field
							id="current-password"
							:label="$t('currentPassword')"
							name="currentPassword"
							prepend-icon="mdi-lock"
							type="password"
							v-model="currentPassword"
							:rules="currentPasswordRules"
							required
						></v-text-field>

						<!-- New Password -->
						<v-text-field
							id="password"
							:label="$t('newPassword')"
							name="password"
							prepend-icon="mdi-lock"
							type="password"
							v-model="newPassword"
							:rules="passwordRules"
							required
						></v-text-field>

						<!-- Confirm New Password -->
						<v-text-field
							id="confirm-password"
							:label="$t('confirmPassword')"
							name="confirmPassword"
							prepend-icon="mdi-lock"
							type="password"
							v-model="confirmPassword"
							:rules="confirmPasswordRules"
							required
						></v-text-field>

						<!-- Error Messages -->
						<v-alert v-model="confirmPassErr" type="error" dense dismissible>
							{{ $t('confirmPasswordError') }}
						</v-alert>
						<v-alert v-model="wrongPassword" type="error" dense dismissible>
							{{ $t('wrongPasswordError') }}
						</v-alert>
						<v-alert v-model="badPassword" type="error" dense dismissible>
							{{ $t('badPassword') }}
						</v-alert>
						<v-alert v-model="generalErr" type="error" dense dismissible>
							{{ $t('generalError') }}
						</v-alert>

						<!-- Success Messages -->
						<v-alert
							v-model="changePassSuccess"
							type="success"
							dense
							dismissible
						>
							{{ $t('changePassSuccess') }}
						</v-alert>
					</v-form>

					<!-- Loader -->
					<div v-if="isLoading" class="text-center">
						<v-progress-circular
							indeterminate
							color="primary"
						></v-progress-circular>
					</div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" large @click="onSubmit">{{
						$t('submit')
					}}</v-btn>
				</v-card-actions>
			</v-card>

			<!-- <p class="sign-in">Or <router-link to="/login">Sign In</router-link></p> -->
		</v-col>
	</v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ChangePassword',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			newPassword: '',
			currentPassword: '',
			confirmPassword: '',
			changePassSuccess: false,
			confirmPassErr: false,
			badPassword: false,
			wrongPassword: false,
			generalErr: false,
			isLoading: false,
			currentPasswordRules: [v => !!v || this.$i18n.t('requiredField')],
			passwordRules: [v => !!v || this.$i18n.t('requiredField')],
			confirmPasswordRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	methods: {
		async onSubmit() {
			this.confirmPassErr = false;
			this.badPassword = false;
			this.generalErr = false;

			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			if (this.newPassword !== this.confirmPassword) {
				this.confirmPassErr = true;
				return;
			}

			this.isLoading = true;

			try {
				const payload = {
					currentPassword: this.currentPassword,
					newPassword: this.newPassword,
				};

				const res = await this.$http.put(
					`${this.url}/auth/updatepassword`,
					payload,
				);

				this.changePassSuccess = true;
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;

				if (error.response.data.error.includes('Path `password`')) {
					this.badPassword = true;
				} else if (error.response.data.error.includes('Password incorrect')) {
					this.wrongPassword = true;
				} else {
					this.generalErr = true;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
