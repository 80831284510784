<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="6">
			<v-card class="elevation-20">
				<v-toolbar color="red darken-2" dark flat>
					<v-toolbar-title>{{ $t('deleteAccount') }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-card-text>
					<p class="delete-warning">{{ $t('deleteWarning') }}</p>

					<!-- Error Messages -->
					<v-alert v-model="generalErr" type="error" dense dismissible>
						{{ $t('generalError') }}
					</v-alert>
					<v-alert v-model="activePets" type="error" dense dismissible>
						{{ $t('plsDelAllPets') }}
					</v-alert>
					<v-alert v-model="activeWalks" type="error" dense dismissible>
						{{ $t('plsDelAllWalks') }}
					</v-alert>

					<!-- Loader -->
					<div v-if="isLoading" class="text-center">
						<v-progress-circular
							indeterminate
							color="primary"
						></v-progress-circular>
					</div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-2" large dark @click="dialogDelete = true"
						><v-icon small> mdi-delete </v-icon>{{ $t('delete') }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-col>

		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDeleteAccount')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>
	</v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'DeleteAccount',
	components: {
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			dialogDelete: false,
			generalErr: false,
			isLoading: false,
			activePets: false,
			activeWalks: false,
		};
	},
	computed: {
		...mapState('auth', ['user']),
	},
	methods: {
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.checkDeleteAccount();
			this.dialogDelete = false;
		},
		async checkForPets() {
			try {
				const res = await this.$http.get(
					`${this.url}/pets/user/${this.user._id}`,
				);

				if (res.data.data.length > 0) {
					this.activePets = true;
				}
			} catch (error) {
				console.error(error);
				this.generalErr = true;
			}
		},
		async checkForWalks() {
			try {
				const res = await this.$http.get(
					`${this.url}/walkPosts/user/${this.user._id}`,
				);

				if (res.data.data.length > 0) {
					this.activeWalks = true;
				}
			} catch (error) {
				console.error(error);
				this.generalErr = true;
			}
		},
		checkDeleteAccount() {
			this.checkForPets();
			this.checkForWalks();

			if (!this.activePets || !this.activeWalks) {
				return;
			} else {
				this.deleteAccount();
			}
		},
		async deleteAccount() {
			this.generalErr = false;
			this.isLoading = true;

			try {
				await this.$http.delete(`${this.url}/users/${this.user._id}`);

				this.$cookies.delete('access_token');
				localStorage.removeItem('user');
				this.$router.push('/');
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;
				this.generalErr = true;
				console.log('gen err from delete');
			}
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.delete-warning {
	font-size: 18px !important;
}
</style>
